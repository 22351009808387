import { FlowState, FlowType, InstancePaymentOptionType } from '../types/remittanceTypes'

export const backOfficeApiPath = process.env.REACT_APP_BACK_OFFICE_API_PATH

export const apiAccountsPath = `${backOfficeApiPath}/accounts`
export const apiCountriesPath = `${backOfficeApiPath}/country`

// auth
export const apiLoginPath = `${apiAccountsPath}/login`
export const apiLogOutPath = `${apiAccountsPath}/logout`
export const getApiRefreshTokensPath = (token: string) =>
  `${apiAccountsPath}/tokens/${token}/refresh`
export const apiTwoFaConfirmPath = `${apiLoginPath}/confirm-2fa`

// permissions
export const apiUserPermissionsPath = `${apiAccountsPath}/permissions`

// 2fa
export const apiAuthenticatorPath = `${apiAccountsPath}/authenticator`
export const apiAuthenticatorConfirmPath = `${apiAccountsPath}/authenticator/confirm`
export const apiAuthenticatorDisablePath = `${apiAccountsPath}/authenticator/disable`

// remittance
const apiKWLPath = `${backOfficeApiPath}/kwl`
export const apiKWLSitesPath = `${apiKWLPath}`
export const apiKWLUsersPath = `${apiKWLPath}/users`
export const getApiKWLByIdPath = (id: number) => `${apiKWLPath}/${id}`
export const getApiKWLSourceCountryPath = (kwlId: number, countryId: number) =>
  `${apiKWLPath}/${kwlId}/source-country/${countryId}`
export const getApiKWLRecieveCountryPath = (kwlId: number, countryId: number) =>
  `${apiKWLPath}/${kwlId}/receive-country/${countryId}`
export const getFlowListApiPath = (id: string) => `${apiKWLPath}/${id}/flows`
export const postFlowStateApiPath = (
  id: string | undefined,
  flowType: FlowType,
  flowState: FlowState
) => `${apiKWLPath}/${id}/flows/${flowType}/${flowState}`
export const postPasswordForEarlyAccess = (id: string | undefined) =>
  `${apiKWLPath}/${id}/early-access`
const remittanceInstanceApiPath = `${backOfficeApiPath}/instances`
export const getBeneficiaryConfigValueApiPath = (id: string) =>
  `${backOfficeApiPath}/instance/${id}/configuration/beneficiary`
export const getBeneficiaryConfigPropApiPath = (id: string) =>
  `${backOfficeApiPath}/instance/${id}/configuration/beneficiary/property`
export const updateBeneficiaryConfigApiPath = (id: string | undefined) =>
  `${backOfficeApiPath}/instance/${id}/configuration/beneficiary`
export const setExpirationEntryForUsersByRiskLevelApiPath = `${backOfficeApiPath}/aml-checks/expiration`

//remittance-currency
export const getInstanceCurrencyListApiPath = (instanceId: string | number) =>
  `${remittanceInstanceApiPath}/${instanceId}/currencies`
export const activateInstanceCurrencyApiPath = (
  instanceId: string | number,
  currencyId: string | number
) => `${remittanceInstanceApiPath}/${instanceId}/currencies/${currencyId}/activate`
export const deactivateInstanceCurrencyApiPath = (
  instanceId: string | number,
  currencyId: string | number
) => `${remittanceInstanceApiPath}/${instanceId}/currencies/${currencyId}/deactivate`
export const getPaymentProviderMethodsApiPath = `${backOfficeApiPath}/payment-providers/methods`
export const getAllCurrenciesApiPath = `${backOfficeApiPath}/currencies`
export const createInstanceCurrencyApiPath = (instanceId: string | number) =>
  `${remittanceInstanceApiPath}/${instanceId}/currencies`
export const updateInstanceCurrencyApiPath = (
  instanceId: string | number,
  currencyId: string | number
) => `${remittanceInstanceApiPath}/${instanceId}/currencies/${currencyId}`
export const getInstanceCurrencyApiPath = (
  instanceId: string | number,
  currencyId: string | number
) => `${remittanceInstanceApiPath}/${instanceId}/currencies/${currencyId}`
export const getAvailableInstanceCurrencyListApiPath = (instanceId: string | number) =>
  `${remittanceInstanceApiPath}/${instanceId}/currencies/available`

//remittance-companies
export const getInstanceCompanyListApiPath = (instanceId: string | number) =>
  `${backOfficeApiPath}/kwl-default-company/instance/${instanceId}`
export const getInstanceCompanyByIdApiPath = (companyId: string | number) =>
  `${backOfficeApiPath}/kwl-default-company/${companyId}`
export const createInstanceCompanyApiPath = `${backOfficeApiPath}/kwl-default-company`
export const updateInstanceCompanyApiPath = (companyId: string | number) =>
  `${backOfficeApiPath}/kwl-default-company/${companyId}`
export const deleteInstanceCompanyApiPath = (companyId: string | number) =>
  `${backOfficeApiPath}/kwl-default-company/${companyId}`
export const getAmlAccessTokenForInstanceCompanyApiPath = (companyId: number | string) => 
  `${backOfficeApiPath}/kwl-default-company/${companyId}/aml-checks/access-token`
export const validateCompanyAccNumberApiPath = `${backOfficeApiPath}/kwl-default-company/validate-bankaccount`

//remittance-agent-groups
export const getAgentGroupListForInstanceApiPath = (instanceId: string | number) =>
  `${backOfficeApiPath}/instance/${instanceId}/agent-group`
export const getAllAgentGroupsApiPath = `${backOfficeApiPath}/agent-groups`
export const assignAgentGroupApiPath = (
  instanceId: string | number,
  agentGroupId: string | number
) => `${backOfficeApiPath}/instance/${instanceId}/agent-group/${agentGroupId}`
export const unassignAgentGroupApiPath = (
  instanceId: string | number,
  agentGroupId: string | number
) => `${backOfficeApiPath}/instance/${instanceId}/agent-group/${agentGroupId}`

//remittance-payment-provider
export const getPaymentProviderListApiPath = `${backOfficeApiPath}/payment-providers`
export const getAllPaymentProviderFeesApiPath = (paymentProviderId: string | number) =>
  `${getPaymentProviderListApiPath}/${paymentProviderId}/fees`
export const getPaymentProviderFeesByCurrency = () => ``
export const createPaymentProviderFeeApiPath = (paymentProviderId: number | string) =>
  `${getPaymentProviderListApiPath}/${paymentProviderId}/fees`
export const updatePaymentProviderFeeApiPath = (paymentProviderFeeId: string | number) =>
  `${getPaymentProviderListApiPath}/fees/${paymentProviderFeeId}`
export const deletePaymentProviderFeeApiPath = (paymentProviderFeeId: number | string) =>
  `${getPaymentProviderListApiPath}/fees/${paymentProviderFeeId}`

//remittance-currency
export const getCountryListApiPath = (countryId: string) => `${backOfficeApiPath}/country/instances/${countryId}`
export const getPaymentOptionByCountry = (instanceId: string, countryId: number | string, type: InstancePaymentOptionType | string) =>
  `${backOfficeApiPath}/payment-options/instances/${instanceId}/countries/${countryId}?type=${type}`
export const getCurrencyListByCountryApiPath = (instanceId: string, countryId: number | string, type: InstancePaymentOptionType) =>
  `${backOfficeApiPath}/payment-options/instances/${instanceId}/countries/${countryId}/currencies?type=${type}`
export const getPaymentProviderListApiPathV2 = `${backOfficeApiPath}/payment-providers`
export const getPaymentMethodListApiPath = `${backOfficeApiPath}/payment-providers/methods`
export const addPaymentOptionByCountryApiPath = `${backOfficeApiPath}/payment-options`
export const updatePaymentOptionsByCountryApiPath = (paymentOptionId: string) =>
  `${backOfficeApiPath}/payment-options/${paymentOptionId}`
export const deletePaymentOptionsByCountryApiPath = (paymentOptionId: string) =>
  `${backOfficeApiPath}/payment-options/${paymentOptionId}`
export const commonCurrencyApiPath = `${backOfficeApiPath}/currencies`
export const removeCommonCurrencyApiPath = (id: string | number) => `${commonCurrencyApiPath}/${id}`


// cashout
const apiCashOutPath = `${backOfficeApiPath}/cash-outs`
export const apiCashOutPendingPath = `${apiCashOutPath}/pending`

// top up
const apiTopUpPath = `${backOfficeApiPath}/top-up-requests`
export const apiTopUpUsdkPath = `${apiTopUpPath}/usdk`
export const apiTopUpCashrailPath = `${apiTopUpPath}/cash-rail`
export const apiTopUpKWLPath = `${apiTopUpPath}/kwl`
export const apiGetTopUpRemittanceDetailsPath = (id: string) => `${apiTopUpPath}/${id}`
export const apiCancelRemittanceTopUp = (topUpId: string) => `${apiTopUpKWLPath}/cancel/${topUpId}`
export const apiRefundRemittanceTopUp = (topUpId: string) => `${apiTopUpKWLPath}/refund/${topUpId}`

// mobile user
export const apiMobileUsersPath = `${backOfficeApiPath}/mobile-users`
export const apiGetMobileUserDetailsPath = (id: string | number) =>
  `${apiMobileUsersPath}/${id}/details`

export const apiGetKlProfileDetailsPath = (id: string | number) =>
  `${apiMobileUsersPath}/${id}/kuva-local/details`
export const apiGetKwlProfileDetailsPath = (id: string | number) =>
  `${apiMobileUsersPath}/${id}/kuva-white-label/details`

export const apiGetAllUserInspections = (id: string | number) =>
  `${backOfficeApiPath}/aml-checks/list/${id}`
export const apiGetVisibleUserInspections = (userId: string | number) =>
  `${backOfficeApiPath}/aml-checks/visible-list/${userId}`
export const apiGetUserDocs = (userId: string | number) =>
  `${backOfficeApiPath}/aml-checks/${userId}/docs/`
export const apiGetUserDocImage = (inspectionId: string | number, imageId: string | number) =>
  `${backOfficeApiPath}/aml-checks/${inspectionId}/images/${imageId}`
export const apiSetUserExpirationDate = (userId: string | number) =>
  `${backOfficeApiPath}/aml-checks/expiration/${userId}`
export const updateRemittanceUserRiskApiPath = (userId: string, riskScore: string | number) =>
  `${apiMobileUsersPath}/${userId}/risk-score/${riskScore}/kwl`
export const updateIgnoreRemittanceTxRiskForUserApiPath = (userId: string) =>
  `${apiMobileUsersPath}/${userId}/ignore-trs-toggle/kwl`

// user blocking records
export const apiUserBlockingRecords = `${backOfficeApiPath}/blocking-records`
export const apiGetBlockingRecordsByUserId = (userId: string) =>
  `${apiUserBlockingRecords}/user/${userId}`
export const apiConfirmBlockingRecord = (entityId: number | string) =>
  `${apiUserBlockingRecords}/${entityId}/confirm`
export const apiResolveBlockingRecords = (entityId: number | string) =>
  `${apiUserBlockingRecords}/${entityId}/resolve`

// transactions
export const apiTransactionsAllPath = `${backOfficeApiPath}/transactions`

//world pay
const apiWpCardPaymentPath = `${backOfficeApiPath}/card-payment`
export const getWpKwlCardPaymentListPath = `${apiWpCardPaymentPath}/kwl-tr`
export const getWpKlCardPaymentListPath = `${apiWpCardPaymentPath}/kl-ord`
export const getWpKlCardPaymentDetailByIdPath = (id: string | number) =>
  `${getWpKlCardPaymentListPath}/${id}`
export const getWpKwlCardPaymentDetailByIdPath = (id: string | number) =>
  `${getWpKwlCardPaymentListPath}/${id}`

//agent groups
export const apiAgentGroupsPath = `${backOfficeApiPath}/agent-groups`
export const apiGetAgentGroupKey = (id: string | number) => `${apiAgentGroupsPath}/${id}/keys`
export const apiGenerateAgentGroupApiKey = (id: string | number | undefined) =>
  `${apiAgentGroupsPath}/${id}/keys`
