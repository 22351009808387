import { styled } from '@mui/material'
import { useMemo } from 'react'
import TableHeader from './TableHeader'
import TableRow from './TableRow'
import { TableConfig } from '../../../../../types/tableTyles'



const StyledCompaniesTable = styled('div')({
  marginBottom: '15px',
  '.table-wrap': {
    overflowX: 'auto',
    overflowY: 'auto',
    borderRadius: '10px',
    boxShadow: '0 0 10px 5px rgba(0, 0, 0, 0.2)',
    '& .table-body': {
      height: 'calc(100vh - 380px)'
    }
  },
})

type CompaniesTableProps<T> = {
  tableConfig: TableConfig<T>
  tableData: T[]
  onClick?: (id: string | number) => void
}

const CompaniesTable = <T extends { extId?: string | number; id?: string | number}>({
  tableConfig,
  tableData,
  onClick,
}: CompaniesTableProps<T>) => {
  const isExistOnClick = useMemo(() => Boolean(onClick), [onClick])
  return (
    <StyledCompaniesTable>
      <div
        className="table-wrap"
      >
        <TableHeader tableConfig={tableConfig} />
        <div className="table-body">
          {tableData.map((rowData, rowIndex) => (
            <TableRow<T>
              key={rowData.extId || rowData.id}
              rowData={rowData}
              rowIndex={rowIndex}
              tableConfig={tableConfig}
              onClick={onClick}
              isExistOnClick={isExistOnClick}
            />
          ))}
        </div>
      </div>
    </StyledCompaniesTable>
  )
}

export default CompaniesTable
