import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Container from '@mui/material/Container'
import { SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import EditingFlows from './EditingFlows'
import BeneficiarySettings from './BeneficiarySettings'
import CurrencyAndFeesTab from './CurrencyAndFeesTab/CurrencyAndFeesTab'
import AgentGroupsTab from './AgentGroupsTab/AgentGroupsTab'
import { styled } from '@mui/material'
import CurrencyTab from './CurrencyTab/CurrencyTab'
import CompaniesTab from './CompaniesTab/CompaniesTab'
// import PaymentProviderTab from './PaymentProviderTab'

type RemittanceTabs =
  | 'flow'
  | 'beneficiary-settings'
  | 'currency-and-fees'
  | 'agent-groups'
  | 'payment-provider'
  | 'currency'
  | 'companies'

const StyledTabs = styled(Tabs)({
  padding: '0 10px',
  '.MuiTabs-scrollButtons': {
    width: '25px',
    '&.Mui-disabled': {
      opacity: 0.3,
    },
  },
})

const SiteEditing = () => {
  const { id } = useParams()

  const [tabValue, setTabValue] = useState<RemittanceTabs>('flow')
  const [queryParams, setQueryParams] = useSearchParams()

  useEffect(() => {
    if (queryParams.get('activeTab') === 'flow') {
      setTabValue('flow')
      return
    }

    if (queryParams.get('activeTab') === 'beneficiary-settings') {
      setTabValue('beneficiary-settings')
      return
    }

    if (queryParams.get('activeTab') === 'currency-and-fees') {
      setTabValue('currency-and-fees')
      return
    }

    if (queryParams.get('activeTab') === 'agent-groups') {
      setTabValue('agent-groups')
      return
    }

    if (queryParams.get('activeTab') === 'currency') {
      setTabValue('currency')
      return
    }

    if (queryParams.get('activeTab') === 'companies') {
      setTabValue('companies')
      return
    }

    setTabValue('flow')
    setQueryParams({ activeTab: 'flow' })
  }, [queryParams, setQueryParams])

  const onChangeTab = useCallback(
    (event: SyntheticEvent, newTabValue: RemittanceTabs) => {
      setTabValue(newTabValue)
      setQueryParams({ activeTab: newTabValue })
    },
    [setQueryParams]
  )
  return (
    <Container className="pt-25 pb-10 h-100-percent" maxWidth="xl">
      <StyledTabs
        value={tabValue}
        onChange={onChangeTab}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        sx={{ marginBottom: '16px' }}
      >
        <Tab value="flow" label="Flow Configuration" />
        <Tab value="beneficiary-settings" label="Beneficiary Settings" />
        <Tab value="currency-and-fees" label="Currency And Fees" />
        <Tab value="agent-groups" label="Agent Groups" />
        <Tab value="currency" label="Currency" />
        <Tab value="companies" label="Companies" />
      </StyledTabs>
      {tabValue === 'flow' && <EditingFlows instanceId={id} />}
      {tabValue === 'beneficiary-settings' && <BeneficiarySettings instanceId={id} />}
      {tabValue === 'currency-and-fees' && <CurrencyAndFeesTab instanceId={id} />}
      {tabValue === 'agent-groups' && <AgentGroupsTab instanceId={id} />}
      {tabValue === 'currency' && <CurrencyTab instanceId={id} />}
      {tabValue === 'companies' && <CompaniesTab instanceId={id} />}
    </Container>
  )
}

export default SiteEditing
