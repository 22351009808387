import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { AmlInspectionStatus, InstanceCompanyFormType } from '../../../../../types/remittanceTypes'
import SelectFieldForm from '../../../../../components/reusable/form/SelectFieldForm'
import { Checkbox, FormControlLabel, Stack, styled, Typography } from '@mui/material'
import TextFieldForm from '../../../../../components/reusable/form/TextFieldForm'
import { yupResolver } from '@hookform/resolvers/yup'
import { remittanceYupSchemes } from '../../../../../utils/yupScheme/remittanceYupSchemes'
import { trimObjectFields } from '../../../../../utils/commonUtils'
import { useAppDispatch, useAppSelector } from '../../../../../store'
import { remittanceActions } from '../../../../../store/actions/kwlActions'

const bankOptions = [
  {
    label: 'CBZ',
    value: 1,
  },
]

const countryOptions = [
  {
    label: 'Zimbabwe',
    value: 240,
  },
]

const StyledInstanceCompanyForm = styled('form')({
  padding: '12px 0',

  '.MuiFormControl-root': {
    width: '50%',
  },
  '.MuiGrid-item': {
    display: 'flex',
    '&.fixed-value-block': {
      padding: '8px 16px 0 16px',
      '.MuiTextField-root': {},
    },
    '&.percent-value-block': {
      padding: '8px 0 0 32px',
    },
    '.MuiCheckbox-root': {
      marginLeft: '12px',
      paddingTop: 0,
    },

    '.MuiFormControl-root': {
      width: 'initial',
      '.MuiInput-input': {
        padding: '1px 0 0 5px',
      },
    },
    '.MuiFormControlLabel-root': {
      alignItems: 'start',
    },
  },
})

type InstanceCompanyFormProps = {
  onConfirm: (body: InstanceCompanyFormType) => void
  variant: 'edit-instance-company' | 'add-instance-company'
  setIsValidNumberField: Dispatch<SetStateAction<boolean>>
}

const InstanceCompanyForm = ({
  onConfirm,
  variant,
  setIsValidNumberField,
}: InstanceCompanyFormProps) => {
  const dispatch = useAppDispatch()

  const { instanceCompanyDetails, accNumberData, accNumberAfterValidation } = useAppSelector(
    state => state.kwl
  )

  const { handleSubmit, setValue, control, watch, setError, clearErrors } =
    useForm<InstanceCompanyFormType>({
      resolver: yupResolver(remittanceYupSchemes.instanceCompanyForm),
    })

  const watchedAccountNumber = watch('accountNumber')

  const isValidAccNumberField = useMemo(() => {
    const currentAccNumber = watchedAccountNumber ? watchedAccountNumber.trim() : ''
    const isValidAccNumberAfter = accNumberData ? accNumberData.isValid : false

    return accNumberAfterValidation === currentAccNumber && isValidAccNumberAfter
  }, [accNumberAfterValidation, accNumberData, watchedAccountNumber])

  useEffect(() => {
    setIsValidNumberField(isValidAccNumberField)
  }, [isValidAccNumberField, setIsValidNumberField])

  const setFormValue = useCallback(() => {
    setValue('bankId', 1)
    setValue('countryId', 240)

    if (variant === 'edit-instance-company') {
      setValue('companyName', instanceCompanyDetails?.companyName || '')
      setValue('accountNumber', instanceCompanyDetails?.accountNumber || '')
      setValue('isActive', instanceCompanyDetails?.isActive || false)
      setValue('notificationEmail', instanceCompanyDetails?.notificationEmail || '')
    }
  }, [
    instanceCompanyDetails?.accountNumber,
    instanceCompanyDetails?.companyName,
    instanceCompanyDetails?.isActive,
    instanceCompanyDetails?.notificationEmail,
    setValue,
    variant,
  ])

  useEffect(() => {
    setFormValue()
  }, [setFormValue])

  useEffect(() => {
		if (typeof accNumberData?.isValid === 'boolean') {
			if (accNumberData?.isValid) {
				return clearErrors('accountNumber')
			} else {
				return setError('accountNumber', { message: accNumberData?.errorDescription })
			}
		}
	}, [
		accNumberData,
		accNumberData?.errorDescription,
		accNumberData?.isValid,
		clearErrors,
		setError,
	])

  const onSubmit = (data: InstanceCompanyFormType) => {
    const trimmedData = trimObjectFields(data)

    if (!isValidAccNumberField) {
			const requestData = {
				accountNumber: data.accountNumber,
				bankId: data.bankId,
			}
			dispatch(remittanceActions.validateCompanyAccNumber(requestData))

			return
		}

    onConfirm(trimmedData)
  }

  return (
    <StyledInstanceCompanyForm id="instance-currency-form" onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="row" justifyContent="space-between" alignItems="start" spacing={2} mb={2}>
        <TextFieldForm<InstanceCompanyFormType>
          name="companyName"
          control={control}
          label="Name"
          type="tel"
          inputMode="numeric"
          variant="outlined"
          autoComplete="off"
          maxLength={64}
          disabled={
            variant === 'edit-instance-company' ||
            instanceCompanyDetails?.amlInspectionStatus === AmlInspectionStatus.Rejected ||
            instanceCompanyDetails?.amlInspectionStatus === AmlInspectionStatus.Unknown
          }
        /><Stack direction="row" justifyContent="space-between" alignItems="center" gap={3}>
          <Typography fontWeight="bold">Status: </Typography>
        <Controller
          name="isActive"
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              sx={{ width: '50%', display: 'flex', justifyContent: 'end' }}
              control={
                <Checkbox
                  onChange={event => {
                    onChange(event.target.checked)
                  }}
                  checked={typeof value === 'undefined' ? false : value}
                  disabled={
                    instanceCompanyDetails?.amlInspectionStatus === AmlInspectionStatus.Rejected ||
                    instanceCompanyDetails?.amlInspectionStatus === AmlInspectionStatus.Unknown
                  }
                />
              }
              label="Activate"
            />
          )}
        /></Stack>
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="start" spacing={2} mb={2}>
        <SelectFieldForm<InstanceCompanyFormType>
          name="bankId"
          control={control}
          label="Select Bank"
          multiple={false}
          options={bankOptions}
          disabled
          // disabled={instanceCompanyDetails?.amlInspectionStatus === AmlInspectionStatus.Rejected ||
          //   instanceCompanyDetails?.amlInspectionStatus === AmlInspectionStatus.Unknown}
        />
        <TextFieldForm<InstanceCompanyFormType>
          name="accountNumber"
          control={control}
          label="Account Number"
          type="tel"
          inputMode="numeric"
          variant="outlined"
          autoComplete="off"
          maxLength={32}
          disabled={
            instanceCompanyDetails?.amlInspectionStatus === AmlInspectionStatus.Rejected ||
            instanceCompanyDetails?.amlInspectionStatus === AmlInspectionStatus.Unknown
          }
        />
      </Stack>

      <Stack direction="row" justifyContent="space-between" alignItems="start" spacing={2} mb={2}>
        <SelectFieldForm<InstanceCompanyFormType>
          name="countryId"
          control={control}
          label="Select Country"
          multiple={false}
          options={countryOptions}
          disabled
          // disabled={instanceCompanyDetails?.amlInspectionStatus === AmlInspectionStatus.Rejected ||
          //   instanceCompanyDetails?.amlInspectionStatus === AmlInspectionStatus.Unknown}
        />
        <TextFieldForm<InstanceCompanyFormType>
          name="notificationEmail"
          control={control}
          label="Email"
          type="text"
          inputMode="text"
          variant="outlined"
          autoComplete="off"
          maxLength={64}
          disabled={
            instanceCompanyDetails?.amlInspectionStatus === AmlInspectionStatus.Rejected ||
            instanceCompanyDetails?.amlInspectionStatus === AmlInspectionStatus.Unknown
          }
        />
      </Stack>
    </StyledInstanceCompanyForm>
  )
}

export default InstanceCompanyForm
