import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../store'
import { remittanceActions } from '../../../../../store/actions/kwlActions'
import SumsubWebSdk from '@sumsub/websdk-react'
import Loader from '../../../../../components/Loader'

const AmlInspectionSection = () => {
  const dispatch = useAppDispatch()
  const token = useAppSelector(state => state.kwl.accessTokenForInstanceCompanyChecking)
  const { instanceCompanyDetails } = useAppSelector(state => state.kwl)

  const goToPendingPage = async (type: string) => {
    // console.log('message', type)
    // if (type === 'idCheck.onApplicantSubmitted' || type === 'idCheck.onApplicantResubmitted') {
    //   await dispatch(userActions.getUserAmlList(flowList))
    //   navigate('/pending')
    // }

    // if (type === 'idCheck.applicantReviewComplete') {
    //   setTimeout(() => {
    //     dispatch(userActions.getUserAmlList(flowList))
    //   }, 3000)
    // }
  }

  const goToPage = async (type: string) => {
    // console.log('error', type)
    // if (type === 'idCheck.onApplicantSubmitted' || type === 'idCheck.onApplicantResubmitted') {
    //   await dispatch(userActions.getUserAmlList(flowList))
    //   navigate('/pending')
    // }

    // if (type === 'idCheck.applicantReviewComplete') {
    //   setTimeout(() => {
    //     dispatch(userActions.getUserAmlList(flowList))
    //   }, 3000)
    // }
  }

  useEffect(() => {
    instanceCompanyDetails?.id &&
      dispatch(remittanceActions.getAmlAccessTokenForInstanceCompany(instanceCompanyDetails?.id))
  }, [dispatch, instanceCompanyDetails?.id])

  return token ? (
    <SumsubWebSdk
      accessToken={token}
      expirationHandler={() => Promise.resolve(token)}
      onMessage={goToPendingPage}
      onError={goToPage}
    />
  ) : (
    <Loader className="h-200px" />
    // null
  )
}

export default AmlInspectionSection
