import { Box, Button, styled } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { useCallback, useEffect, useMemo } from 'react'
import { openGlobalModal } from '../../../../store/reducers/globalModalReducer'
import { remittanceActions } from '../../../../store/actions/kwlActions'
import CompaniesTable from './table/CompaniesTable'
import { InstanceCompanyDetails } from '../../../../types/remittanceTypes'
import remittanceTableConfig from '../../../../utils/config/tableConfig/remittance'
import { clearInstanceCompanyList } from '../../../../store/reducers/kwlReducers'

type CompaniesTabProps = {
  instanceId: string | undefined
}

const StyledCompaniesTab = styled(Box)({
  padding: '8px 16px',
  '.add-currency-btn': {
    marginBottom: '20px',
  },
})

const CompaniesTab = ({ instanceId }: CompaniesTabProps) => {
  const dispatch = useAppDispatch()

  const { instanceCompanyList } = useAppSelector(state => state.kwl)

  const tableConfig = useMemo(() => {
    return remittanceTableConfig.instanceCompaniesTableConfig(instanceId)
  }, [instanceId])

  useEffect(() => {
    if (instanceId) {
      dispatch(remittanceActions.getInstanceCompanyList(instanceId))
    }

    return () => {
      dispatch(clearInstanceCompanyList())
    }
  }, [dispatch, instanceId])

  const addNewCompany = useCallback(() => {
    dispatch(openGlobalModal({ variant: 'add-instance-company', id: instanceId }))
  }, [dispatch, instanceId])

  return (
    <StyledCompaniesTab>
      <Button
        size="medium"
        variant="contained"
        onClick={addNewCompany}
        className="add-currency-btn"
      >
        Add New Company
      </Button>
      {instanceCompanyList.length > 0 && (
        <CompaniesTable<InstanceCompanyDetails>
          tableConfig={tableConfig}
          tableData={instanceCompanyList}
        />
      )}
    </StyledCompaniesTab>
  )
}

export default CompaniesTab
