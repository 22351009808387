import * as yup from 'yup'

const paymentProviderFeeForm = yup.object({
  fixedValue: yup.string().typeError('No valid').required('No valid'),
  percentValue: yup.string().typeError('No valid').required('No valid'),
})

const settingUserExpirationForm = yup.object({
  userRiskLevelMarker: yup
    .string()
    .typeError('No Valid')
    .trim()
    .required('Required Field'),
  months: yup
    .string()
    .typeError('No Valid')
    .trim()
    .required('Required Field'),
})

const commonCurrencyForm = yup.object({
  id: yup
    .string()
    .typeError('No Valid')
    .trim()
    .required('Required Field'),
  sign: yup
    .string()
    .typeError('No Valid')
    .trim()
    .required('Required Field'),
  displayName: yup
    .string()
    .typeError('No Valid')
    .trim()
    .required('Required Field'),
})

const instanceCompanyForm = yup.object({
  companyName: yup.string()
    .typeError('No Valid')
    .trim()
    .required('Required Field')
    .matches(/^[0-9a-zA-Z-\s]*$/, 'Please, enter a valid Company Name'),
  accountNumber: yup.string()
    .typeError('No Valid')
    .trim()
    .required('Required Field')
    .matches(/^[0-9a-zA-Z-]*$/, 'Please, enter a valid Account Number'),
  notificationEmail: yup.string()
    .typeError('Please, enter beneficiary email')
    .required('Required Field')
    .matches(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Please, enter a valid email')
})

export const remittanceYupSchemes = {
  paymentProviderFeeForm,
  settingUserExpirationForm,
  commonCurrencyForm,
  instanceCompanyForm
}
