import { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, styled } from '@mui/material'
import {
  StyledCloseButtonModal,
  StyledDialog,
  StyledDialogActions,
  StyledDialogBody,
  StyledDialogContent,
  StyledDialogHeader,
  StyledDialogTitle,
} from '../../../../../components/modal/styledModalComponent'
import { useAppDispatch, useAppSelector } from '../../../../../store'
import { closeGlobalModal } from '../../../../../store/reducers/globalModalReducer'

import CancelIcon from '@mui/icons-material/Cancel'
import { remittanceActions } from '../../../../../store/actions/kwlActions'
import { AmlInspectionStatus, InstanceCompanyFormType } from '../../../../../types/remittanceTypes'
import {
  clearAccessTokenForInstanceCompanyChecking,
  clearInstanceCompanyDetails,
} from '../../../../../store/reducers/kwlReducers'
import InstanceCompanyForm from './InstanceCompanyForm'
import { omit } from 'lodash'
import AmlInspectionSection from './AmlInspectionSection'
import Loader from '../../../../../components/Loader'

const StyledDialogModalTitle = styled(StyledDialogTitle)({
  width: '100%',
  paddingLeft: 0,
})

const StyledInstanceCurrencyModal = styled(StyledDialog)({
  '.MuiDialog-paper': {
    width: '600px',
  },
})

type InstanceCompanyModalProps = {
  isOpenDialog: boolean
  variant: 'edit-instance-company' | 'add-instance-company'
}

const InstanceCompanyModal = ({ isOpenDialog, variant }: InstanceCompanyModalProps) => {
  const dispatch = useAppDispatch()

  const { id: instanceId, additionalId: companyId } = useAppSelector(state => state.globalModal)
  const { instanceCompanyDetails } = useAppSelector(state => state.kwl)
  const { getInstanceCompanyByIdLoadState, validateCompanyAccNumberLoadState } = useAppSelector(
    state => state.kwl
  )

  const [isAmlInspectionSection, setIsAmlInspectionSection] = useState(false)
  const [isValidAccNumberField, setIsValidNumberField] = useState(false)

  const isLoading = useMemo(
    () => getInstanceCompanyByIdLoadState.isLoading,
    [getInstanceCompanyByIdLoadState.isLoading]
  )

  const isShownAmlInspectionBtn = useMemo(() => {
    return (
      variant === 'edit-instance-company' &&
      (instanceCompanyDetails?.amlInspectionStatus === AmlInspectionStatus.Unknown ||
        instanceCompanyDetails?.amlInspectionStatus === AmlInspectionStatus.Initiated ||
        instanceCompanyDetails?.amlInspectionStatus === AmlInspectionStatus.Pending ||
        instanceCompanyDetails?.amlInspectionStatus === AmlInspectionStatus.RejectedWithRetry)
    )
  }, [instanceCompanyDetails?.amlInspectionStatus, variant])

  useEffect(() => {
    if (variant === 'edit-instance-company') {
      companyId && dispatch(remittanceActions.getInstanceCompanyById(companyId))
    }
  }, [companyId, dispatch, variant])

  const closeModal = useCallback(() => {
    dispatch(closeGlobalModal())
    dispatch(clearInstanceCompanyDetails())
    dispatch(clearAccessTokenForInstanceCompanyChecking())
    dispatch(remittanceActions.getInstanceCompanyList(instanceId))
  }, [dispatch, instanceId])

  const onConfirm = useCallback(
    (data: InstanceCompanyFormType) => {
      if (variant === 'add-instance-company') {
        const body = { ...data, instanceId: instanceId }

        dispatch(remittanceActions.createInstanceCompany(body))
          .unwrap()
          .then(() => {
            setIsAmlInspectionSection(true)
          })
      }
      if (variant === 'edit-instance-company') {
        const omittedData = omit(data, ['companyName', 'countryId'])
        const arg = {
          body: { ...omittedData, instanceId },
          companyId: companyId || 0,
        }

        companyId && dispatch(remittanceActions.updateInstanceCompany(arg))
        closeModal()
      }
    },
    [closeModal, companyId, dispatch, instanceId, variant]
  )
  const titleText = useMemo(() => {
    if (variant === 'add-instance-company') {
      return 'Adding New Company'
    }
    if (variant === 'edit-instance-company') {
      return 'Editing Company'
    }
  }, [variant])

  return (
    <>
      {!isLoading && (
        <>
          <StyledInstanceCurrencyModal open={isOpenDialog} onClose={closeModal}>
            {validateCompanyAccNumberLoadState.isLoading && <Loader className="fixed" />}
            <StyledDialogHeader>
              <StyledDialogModalTitle>{titleText}</StyledDialogModalTitle>
              <StyledCloseButtonModal onClick={closeModal}>
                Close
                <CancelIcon />
              </StyledCloseButtonModal>
            </StyledDialogHeader>
            <StyledDialogBody>
              {isAmlInspectionSection ? (
                <StyledDialogContent>
                  <AmlInspectionSection />
                </StyledDialogContent>
              ) : (
                <>
                  <StyledDialogContent>
                    <InstanceCompanyForm
                      onConfirm={onConfirm}
                      variant={variant}
                      setIsValidNumberField={setIsValidNumberField}
                    />
                    {isShownAmlInspectionBtn && (
                      <Button
                        type="button"
                        size="large"
                        variant="contained"
                        onClick={() => setIsAmlInspectionSection(prev => !prev)}
                        sx={{ width: '250px', marginBottom: '32px' }}
                      >
                        Pass Aml Inspection
                      </Button>
                    )}
                  </StyledDialogContent>
                  <StyledDialogActions>
                    <Button size="large" variant="outlined" onClick={closeModal}>
                      Cancel
                    </Button>
                    {instanceCompanyDetails?.amlInspectionStatus !== AmlInspectionStatus.Rejected &&
                      instanceCompanyDetails?.amlInspectionStatus !==
                        AmlInspectionStatus.Unknown && (
                        <Button
                          form="instance-currency-form"
                          type="submit"
                          size="large"
                          variant="contained"
                        >
                          {isValidAccNumberField ? 'Save' : 'Validate'}
                        </Button>
                      )}
                  </StyledDialogActions>
                </>
              )}
            </StyledDialogBody>
          </StyledInstanceCurrencyModal>
        </>
      )}
    </>
  )
}

export default InstanceCompanyModal
