import { createSlice } from '@reduxjs/toolkit'

import { InstancePaymentOptionType, RemittanceStateType } from '../../types/remittanceTypes'
import { remittanceActions } from '../actions/kwlActions'

const initialState: RemittanceStateType = {
  isLoading: false,
  isLoaded: false,
  site: {
    list: [],
    listFetchStart: false,
    listFetchFinished: false,
  },
  user: {
    list: [],
    listFetchStart: false,
    listFetchFinished: false,
  },
  flowList: [],
  data: {
    sourceCountryIds: [],
    receiveCountryIds: [],
    id: 0,
    extId: '',
    hostUrl: '',
    logoUrl: '',
    isDefault: false,
    confirmationEmailText: '',
    paymentCompleteSmsText: '',
    redeemCodeText: '',
    senderName: '',
    senderEmail: '',
    agentGroupId: 0,
  },
  dataFetchStart: false,
  dataFetchFinished: false,
  setCountryStart: false,
  setCountryFinished: false,
  deleteCountryStart: false,
  deleteCountryFinished: false,
  beneficiaryConfig: null,
  error: '',

  instanceCurrencyList: [],
  getInstanceCurrencyListLoadState: {
    isLoading: false,
    isLoaded: false,
  },

  availableInstanceCurrencyList: [],
  getAvailableInstanceCurrencyListLoadState: {
    isLoading: false,
    isLoaded: false,
  },

  allCurrencyList: [],
  getAllCurrenciesLoadState: {
    isLoading: false,
    isLoaded: false,
  },

  paymentProviderMethods: [],
  getPaymentProviderMethodsLoadState: {
    isLoading: false,
    isLoaded: false,
  },

  instanceCurrencyDetails: null,
  getInstanceCurrencyLoadState: {
    isLoading: false,
    isLoaded: false,
  },

  allAgentGroups: [],
  getAllAgentGroupsLoadState: {
    isLoading: false,
    isLoaded: false,
  },
  searchFieldForAllAgentGroups: '',

  agentGroupsForInstance: [],
  getAgentGroupListForInstanceLoadState: {
    isLoading: false,
    isLoaded: false,
  },

  //remittance-payment-provider
  defaultPaymentProviderFeesByCurrency: [],
  // getAllPaymentProviderFeesLoadState: {
  //   isLoading: false,
  //   isLoaded: false,
  // },
  paymentProviderFeesByCurrency: null,
  getPaymentProviderListLoadState: {
    isLoading: false,
    isLoaded: false,
  },

  cashInCountries: [],
  cashOutCountries: [],
  selectedCashInCountry: null,
  selectedCashOutCountry: null,

  cashInPaymentOptionsByCountry: [],
  cashOutPaymentOptionsByCountry: [],

  // currentPaymentOptionByCountry: null

  currencyListByCountry: [],
  paymentProviderList: [],
  paymentMethodList: [],

  commonCurrencyList: [],
  getCommonCurrencyListLoadState: {
    isLoading: false,
    isLoaded: false,
  },

  instanceCompanyDetails: null,
  getInstanceCompanyByIdLoadState: {
    isLoading: false,
    isLoaded: false,
  },
  instanceCompanyList: [],
  accessTokenForInstanceCompanyChecking: '',
  validateCompanyAccNumberLoadState: {
    isLoading: false,
    isLoaded: false,
  },
  accNumberAfterValidation: '',
  accNumberData: null
}

const kwl = createSlice({
  name: 'kwl',
  initialState,
  reducers: {
    setSearchFieldForAllAgentGroups: (state, action) => {
      state.searchFieldForAllAgentGroups = action.payload
    },
    clearSearchFieldForAllAgentGroups: state => {
      state.searchFieldForAllAgentGroups = ''
    },
    removeBeneficiaryConfig: state => {
      state.beneficiaryConfig = null
    },
    removeAfterClosingInstanceCurrencyModal: state => {
      state.paymentProviderMethods = []
      state.instanceCurrencyDetails = null
      state.defaultPaymentProviderFeesByCurrency = []
    },
    removeInstanceCurrencyList: state => {
      state.instanceCurrencyList = []
    },
    clearInstanceCompanyDetails: state => {
      state.instanceCompanyDetails = null
    },
    clearInstanceCompanyList: state => {
      state.instanceCompanyList = []
    },
    clearAccessTokenForInstanceCompanyChecking: state => {
      state.accessTokenForInstanceCompanyChecking = ''
      state.accNumberAfterValidation = ''
      state.accNumberData = null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(remittanceActions.fetchKWLSiteList.pending, state => {
        state.site.listFetchStart = true
      })
      .addCase(remittanceActions.fetchKWLSiteList.fulfilled, (state, action) => {
        state.site.listFetchFinished = true
        state.site.listFetchStart = false
        state.site.list = action.payload
      })
      .addCase(remittanceActions.fetchKWLSiteList.rejected, (state, action) => {
        state.error = action.payload!.message
      })

      .addCase(remittanceActions.fetchKWLUserList.pending, state => {
        state.user.listFetchStart = true
      })
      .addCase(remittanceActions.fetchKWLUserList.fulfilled, (state, action) => {
        state.user.listFetchFinished = true
        state.user.listFetchStart = false
        state.user.list = action.payload
      })
      .addCase(remittanceActions.fetchKWLUserList.rejected, (state, action) => {
        state.error = action.payload!.message
      })

      .addCase(remittanceActions.fetchKWLDataById.pending, state => {
        state.dataFetchStart = true
      })
      .addCase(remittanceActions.fetchKWLDataById.fulfilled, (state, action) => {
        state.dataFetchFinished = true
        state.dataFetchStart = false
        state.data = action.payload
      })
      .addCase(remittanceActions.fetchKWLDataById.rejected, (state, action) => {
        state.error = action.payload!.message
      })

      .addCase(remittanceActions.setKWLCountry().pending, state => {
        state.setCountryStart = true
      })
      .addCase(remittanceActions.setKWLCountry().fulfilled, state => {
        state.setCountryFinished = true
        state.setCountryStart = false
      })
      .addCase(remittanceActions.setKWLCountry().rejected, (state, action) => {
        state.error = action.payload!.message
      })

      .addCase(remittanceActions.deleteKWLCountry().pending, state => {
        state.deleteCountryStart = true
      })
      .addCase(remittanceActions.deleteKWLCountry().fulfilled, state => {
        state.deleteCountryFinished = true
        state.deleteCountryStart = false
      })
      .addCase(remittanceActions.deleteKWLCountry().rejected, (state, action) => {
        state.error = action.payload!.message
      })

      //remittance-flow
      .addCase(remittanceActions.getFlowList.pending, state => {
        state.error = ''
        state.flowList = []
      })
      .addCase(remittanceActions.getFlowList.fulfilled, (state, action) => {
        state.flowList = action.payload
      })
      .addCase(remittanceActions.postFlowState.fulfilled, (state, action) => {
        state.flowList = action.payload
      })

      .addCase(remittanceActions.getBeneficiaryConfig.pending, state => {
        state.error = ''
        state.isLoading = true
      })
      .addCase(remittanceActions.getBeneficiaryConfig.fulfilled, (state, action) => {
        state.error = ''
        state.isLoading = false
        state.isLoaded = true
        state.beneficiaryConfig = action.payload
      })
      .addCase(remittanceActions.getBeneficiaryConfig.rejected, state => {
        state.isLoading = false
        state.isLoaded = false
      })

      .addCase(remittanceActions.getInstanceCurrencyList.pending, state => {
        state.getInstanceCurrencyListLoadState = {
          isLoading: true,
          isLoaded: false,
        }
      })
      .addCase(remittanceActions.getInstanceCurrencyList.fulfilled, (state, action) => {
        state.getInstanceCurrencyListLoadState = {
          isLoading: false,
          isLoaded: true,
        }
        state.instanceCurrencyList = action.payload
      })
      .addCase(remittanceActions.getInstanceCurrencyList.rejected, state => {
        state.getInstanceCurrencyListLoadState = {
          isLoading: false,
          isLoaded: false,
        }
      })

      .addCase(remittanceActions.getAvailableInstanceCurrencyList.pending, state => {
        state.getAvailableInstanceCurrencyListLoadState = {
          isLoading: true,
          isLoaded: false,
        }
      })
      .addCase(remittanceActions.getAvailableInstanceCurrencyList.fulfilled, (state, action) => {
        state.getAvailableInstanceCurrencyListLoadState = {
          isLoading: false,
          isLoaded: true,
        }
        state.availableInstanceCurrencyList = action.payload
      })
      .addCase(remittanceActions.getAvailableInstanceCurrencyList.rejected, state => {
        state.getAvailableInstanceCurrencyListLoadState = {
          isLoading: false,
          isLoaded: false,
        }
      })

      .addCase(remittanceActions.getAllCurrencies.pending, state => {
        state.getAllCurrenciesLoadState = {
          isLoading: true,
          isLoaded: false,
        }
      })
      .addCase(remittanceActions.getAllCurrencies.fulfilled, (state, action) => {
        state.getAllCurrenciesLoadState = {
          isLoading: false,
          isLoaded: true,
        }
        state.allCurrencyList = action.payload
      })
      .addCase(remittanceActions.getAllCurrencies.rejected, state => {
        state.getAllCurrenciesLoadState = {
          isLoading: false,
          isLoaded: false,
        }
      })

      .addCase(remittanceActions.getPaymentProviderMethods.pending, state => {
        state.getPaymentProviderMethodsLoadState = {
          isLoading: true,
          isLoaded: false,
        }
      })
      .addCase(remittanceActions.getPaymentProviderMethods.fulfilled, (state, action) => {
        state.paymentProviderMethods = action.payload
        state.getPaymentProviderMethodsLoadState = {
          isLoading: false,
          isLoaded: true,
        }
      })
      .addCase(remittanceActions.getPaymentProviderMethods.rejected, state => {
        state.getPaymentProviderMethodsLoadState = {
          isLoading: false,
          isLoaded: false,
        }
      })

      .addCase(remittanceActions.getInstanceCurrency.pending, state => {
        state.getInstanceCurrencyLoadState = {
          isLoading: true,
          isLoaded: false,
        }
      })
      .addCase(remittanceActions.getInstanceCurrency.fulfilled, (state, action) => {
        state.getInstanceCurrencyLoadState = {
          isLoading: false,
          isLoaded: true,
        }
        state.instanceCurrencyDetails = action.payload
      })
      .addCase(remittanceActions.getInstanceCurrency.rejected, state => {
        state.getInstanceCurrencyLoadState = {
          isLoading: false,
          isLoaded: false,
        }
      })

      .addCase(remittanceActions.getAllAgentGroups.pending, state => {
        state.getAllAgentGroupsLoadState = {
          isLoading: true,
          isLoaded: false,
        }
      })
      .addCase(remittanceActions.getAllAgentGroups.fulfilled, (state, action) => {
        state.getAllAgentGroupsLoadState = {
          isLoading: false,
          isLoaded: true,
        }
        state.allAgentGroups = action.payload.items.filter(
          (item: any) => !state.agentGroupsForInstance.some(agentGroup => item.id === agentGroup.id)
        )
      })
      .addCase(remittanceActions.getAllAgentGroups.rejected, state => {
        state.getAllAgentGroupsLoadState = {
          isLoading: false,
          isLoaded: false,
        }
      })

      .addCase(remittanceActions.getAgentGroupListForInstance.pending, state => {
        state.getAgentGroupListForInstanceLoadState = {
          isLoading: true,
          isLoaded: false,
        }
      })
      .addCase(remittanceActions.getAgentGroupListForInstance.fulfilled, (state, action) => {
        state.getAgentGroupListForInstanceLoadState = {
          isLoading: false,
          isLoaded: true,
        }
        state.agentGroupsForInstance = action.payload
      })
      .addCase(remittanceActions.getAgentGroupListForInstance.rejected, state => {
        state.getAgentGroupListForInstanceLoadState = {
          isLoading: false,
          isLoaded: false,
        }
      })

      //remittance-payment-provider
      // .addCase(remittanceActions.getAllPaymentProviderFees.pending, state => {
      //   state.getAllPaymentProviderFeesLoadState = {
      //     isLoading: true,
      //     isLoaded: false,
      //   }
      // })
      .addCase(remittanceActions.getPaymentProviderFeesByInstance.fulfilled, (state, action) => {
        // state.getAllPaymentProviderFeesLoadState = {
        //   isLoading: false,
        //   isLoaded: true,
        // }
        state.defaultPaymentProviderFeesByCurrency = [
          ...state.defaultPaymentProviderFeesByCurrency,
          ...action.payload,
        ]
      })
      // .addCase(remittanceActions.getAllPaymentProviderFees.rejected, state => {
      //   state.getAllPaymentProviderFeesLoadState = {
      //     isLoading: false,
      //     isLoaded: false,
      //   }
      // })
      .addCase(remittanceActions.getPaymentProviderFeesByCurrency.fulfilled, (state, action) => {
        state.paymentProviderFeesByCurrency = action.payload
      })
      .addCase(remittanceActions.getPaymentProviderList.pending, state => {
        state.getPaymentProviderListLoadState = {
          isLoading: true,
          isLoaded: false,
        }
      })
      .addCase(remittanceActions.getPaymentProviderList.fulfilled, (state, action) => {
        state.getPaymentProviderListLoadState = {
          isLoading: false,
          isLoaded: true,
        }
        state.paymentProviderList = action.payload
      })
      .addCase(remittanceActions.getPaymentProviderList.rejected, state => {
        state.getPaymentProviderListLoadState = {
          isLoading: false,
          isLoaded: false,
        }
      })

      .addCase(remittanceActions.getCountryList.fulfilled, (state, action) => {
        state.cashInCountries = action.payload.sourceCountries
        state.cashOutCountries = action.payload.receiveCountries
      })

      .addCase(remittanceActions.getPaymentOptionsByCountry.fulfilled, (state, action) => {
        if (Number(action.meta.arg.type) === InstancePaymentOptionType.CashIn) {
          state.cashInPaymentOptionsByCountry = action.payload
          state.selectedCashInCountry = state.cashInCountries.filter(cashInCountry =>
            cashInCountry.id === action.meta.arg.countryId)[0] || null
        }
        if (Number(action.meta.arg.type) === InstancePaymentOptionType.CashOut) {
          state.cashOutPaymentOptionsByCountry = action.payload
          state.selectedCashOutCountry = state.cashOutCountries.filter(cashOutCountry =>
            cashOutCountry.id === action.meta.arg.countryId)[0] || null
        }
      })

      .addCase(remittanceActions.getCurrencyListByCountry.fulfilled, (state, action) => {
        state.currencyListByCountry = action.payload
      })
      .addCase(remittanceActions.getPaymentProviderListV2.fulfilled, (state, action) => {
        state.paymentProviderList = action.payload
      })
      .addCase(remittanceActions.getPaymentMethodList.fulfilled, (state, action) => {
        state.paymentMethodList = action.payload
      })


      .addCase(remittanceActions.getCommonCurrencyList.pending, state => {
        state.getCommonCurrencyListLoadState = {
          isLoading: true,
          isLoaded: false,
        }
      })
      .addCase(remittanceActions.getCommonCurrencyList.fulfilled, (state, action) => {
        state.commonCurrencyList = action.payload
        state.getCommonCurrencyListLoadState = {
          isLoading: false,
          isLoaded: true,
        }
      })
      .addCase(remittanceActions.getCommonCurrencyList.rejected, state => {
        state.getCommonCurrencyListLoadState = {
          isLoading: false,
          isLoaded: false,
        }
      })

      //remittance-company
      .addCase(remittanceActions.getInstanceCompanyList.pending, state => {
        // state.getInstanceCurrencyListLoadState = {
        //   isLoading: true,
        //   isLoaded: false,
        // }
      })
      .addCase(remittanceActions.getInstanceCompanyList.fulfilled, (state, action) => {
        // state.getInstanceCurrencyListLoadState = {
        //   isLoading: false,
        //   isLoaded: true,
        // }
        state.instanceCompanyList = action.payload
      })
      .addCase(remittanceActions.getInstanceCompanyList.rejected, state => {
        // state.getInstanceCurrencyListLoadState = {
        //   isLoading: false,
        //   isLoaded: false,
        // }
      })
      .addCase(remittanceActions.getInstanceCompanyById.pending, state => {
        state.getInstanceCompanyByIdLoadState = {
          isLoading: true,
          isLoaded: false,
        }
      })
      .addCase(remittanceActions.getInstanceCompanyById.fulfilled, (state, action) => {
        state.getInstanceCompanyByIdLoadState = {
          isLoading: false,
          isLoaded: true,
        }
        state.instanceCompanyDetails = action.payload
      })
      .addCase(remittanceActions.getInstanceCompanyById.rejected, state => {
        state.getInstanceCompanyByIdLoadState = {
          isLoading: false,
          isLoaded: false,
        }
      })
      .addCase(remittanceActions.getAmlAccessTokenForInstanceCompany.fulfilled, (state, action) => {
        state.accessTokenForInstanceCompanyChecking = action.payload.token
      })

      .addCase(remittanceActions.createInstanceCompany.fulfilled, (state, action) => {
        state.instanceCompanyDetails = action.payload
      })

      .addCase(remittanceActions.validateCompanyAccNumber.pending, state => {
        state.validateCompanyAccNumberLoadState = {
          isLoading: true,
          isLoaded: false
        }
      })
      .addCase(remittanceActions.validateCompanyAccNumber.fulfilled, (state, action) => {
        state.validateCompanyAccNumberLoadState = {
          isLoading: false,
          isLoaded: true
        }
        state.accNumberData = action.payload
        state.accNumberAfterValidation = action.meta.arg.accountNumber
      })
      .addCase(remittanceActions.validateCompanyAccNumber.rejected, state => {
        state.validateCompanyAccNumberLoadState = {
          isLoading: false,
          isLoaded: false
        }
      })

  },
})

export const {
  removeBeneficiaryConfig,
  removeAfterClosingInstanceCurrencyModal,
  removeInstanceCurrencyList,
  setSearchFieldForAllAgentGroups,
  clearSearchFieldForAllAgentGroups,
  clearInstanceCompanyDetails,
  clearInstanceCompanyList,
  clearAccessTokenForInstanceCompanyChecking,
} = kwl.actions

export default kwl.reducer
