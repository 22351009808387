import { Button, styled } from '@mui/material'
import { useCallback } from 'react'
import { useAppDispatch } from '../../../../../store'
import { openGlobalModal } from '../../../../../store/reducers/globalModalReducer'

const StyledCompanyControlButtonCell = styled('div')({
  display: 'flex',
  gridGap: '5px',
})

type CompanyControlButtonCellProps = {
  instanceId?: string
  companyId: number
}

const CompanyControlButtonCell = ({ instanceId, companyId }: CompanyControlButtonCellProps) => {
  const dispatch = useAppDispatch()

  const onEditBtn = useCallback(() => {
    instanceId &&
      dispatch(
        openGlobalModal({
          variant: 'edit-instance-company',
          id: instanceId,
          additionalId: companyId,
        })
      )
  }, [companyId, dispatch, instanceId])

  const onDeleteBtn = useCallback(() => {
    dispatch(
      openGlobalModal({
        variant: 'delete-instance-company',
        id: instanceId,
        additionalId: companyId,
      })
    )
  }, [companyId, dispatch, instanceId])

  return (
    <StyledCompanyControlButtonCell>
      <Button size="small" variant="contained" onClick={onEditBtn}>
        Edit
      </Button>
      <Button size="small" variant="contained" color="error" onClick={onDeleteBtn}>
        Delete
      </Button>
    </StyledCompanyControlButtonCell>
  )
}

export default CompanyControlButtonCell
