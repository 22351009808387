import CircularProgress from '@mui/material/CircularProgress'

type LoaderProps = {
  className?: string
}

export default ({ className }: LoaderProps) => {
  return (
    <div className={`loader${className ? ` ${className}` : ''}`}>
      <div className="spinner">
        <CircularProgress color="secondary" />
      </div>
    </div>
  )
}
