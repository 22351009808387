import CommonCurrencyControlButtonCell from '../../../pages/KuvaWhiteLabel/RemittanceSettings/currencies/CurrenciesTable/CommonCurrencyControlButtonCell'
import {
  AgentGroupAssignBtn,
  AgentGroupUnassignBtn,
} from '../../../pages/KuvaWhiteLabel/Sites/AgentGroupsTab/AgentGroupsTable/AgentGroupsControlBtn'
import CompanyControlButtonCell from '../../../pages/KuvaWhiteLabel/Sites/CompaniesTab/table/CompanyControlButtonCell'
import CurrencyControlButtonCell from '../../../pages/KuvaWhiteLabel/Sites/CurrencyAndFeesTab/CurrencyAndFeesTable/CurrencyControlButtonCell'
import ControlButtonCell from '../../../pages/KuvaWhiteLabel/Sites/CurrencyTab/CurrencyTable.tsx/ControlButtonCell'
import GettingDataByCountryBtn from '../../../pages/KuvaWhiteLabel/Sites/CurrencyTab/CurrencyTable.tsx/GetingDataByCountryBtn'
import {
  AgentGroup,
  Country,
  CurrencyDetails,
  PaymentMethod,
  PaymentOptionByCountry,
  InstancePaymentOptionType,
  PaymentProviderFee,
  Currency,
  InstanceCompanyDetails,
  AmlInspectionStatus,
} from '../../../types/remittanceTypes'
import { TableConfig } from '../../../types/tableTyles'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

const currencyTableConfig = (): TableConfig<Currency> => {
  return {
    columns: [
      {
        id: 'id',
        header: 'Id',
        contentColumn: item => item.id,
        // width: '30%',
        width: {
          minWidth: '150px',
          width: '15%',
        },
      },

      {
        id: 'sign',
        header: 'Sign',
        contentColumn: item => item.sign,
        // width: '20%',
        width: {
          minWidth: '150px',
          width: '15%',
        },
      },
      {
        id: 'displayName',
        header: 'Display Name',
        contentColumn: item => item.displayName,
        // width: '20%',
        width: {
          minWidth: '250px',
          width: '35%',
        },
      },

      {
        id: 'control-btn',
        header: '',
        contentColumn: item => <CommonCurrencyControlButtonCell currencyDetails={item} />,
        width: {
          minWidth: '210px',
          width: '35%',
        },
      },
    ],
  }
}

const instanceCurrencyTableConfig = (instanceId?: string): TableConfig<CurrencyDetails> => {
  return {
    columns: [
      {
        id: 'currency',
        header: 'Currency',
        contentColumn: item => item.currency.id,
        // width: '30%',
        width: {
          minWidth: '120px',
          width: '10%',
        },
      },

      {
        id: 'cash-in-fixed-fee',
        header: 'Cash-in Fixed Fee',
        contentColumn: item =>
          item.cashInFee?.fixedValue || item.cashInFee?.fixedValue === 0
            ? item.cashInFee?.fixedValue.toFixed(2)
            : '-',
        // width: '20%',
        width: {
          minWidth: '145px',
          width: '17%',
        },
      },
      {
        id: 'cash-in-percent-fee',
        header: 'Cash-in Percent Fee',
        contentColumn: item =>
          item.cashInFee?.percentValue || item.cashInFee?.percentValue === 0
            ? `${item.cashInFee?.percentValue.toFixed(2)}%`
            : '-',
        // width: '20%',
        width: {
          minWidth: '145px',
          width: '17%',
        },
      },
      {
        id: 'kuva-cash-in-fixed-fee',
        header: 'Cash-in Kuva Fixed Fee',
        contentColumn: item =>
          item.kuvaCashInFee?.fixedValue || item.kuvaCashInFee?.fixedValue === 0
            ? item.kuvaCashInFee?.fixedValue.toFixed(2)
            : '-',
        // width: '20%',
        width: {
          minWidth: '145px',
          width: '18%',
        },
      },
      {
        id: 'kuva-cash-in-percent-fee',
        header: 'Cash-in Kuva Percent Fee',
        contentColumn: item =>
          item.kuvaCashInFee?.percentValue || item.kuvaCashInFee?.percentValue === 0
            ? `${item.kuvaCashInFee?.percentValue.toFixed(2)}%`
            : '-',
        // width: '20%',
        width: {
          minWidth: '145px',
          width: '18%',
        },
      },
      {
        id: 'control-btn',
        header: '',
        contentColumn: item => (
          <CurrencyControlButtonCell currencyDetails={item} instanceId={instanceId} />
        ),
        width: {
          minWidth: '225px',
          width: '20%',
        },
      },
    ],
  }
}

const agentGroupsForInstanceTableConfig = (instanceId?: string): TableConfig<AgentGroup> => {
  return {
    columns: [
      {
        id: 'name',
        header: 'Name',
        contentColumn: item => item.title,
        width: {
          minWidth: '190px',
          width: '70%',
        },
      },

      {
        id: 'control-btn',
        header: '',
        contentColumn: item => (
          <AgentGroupUnassignBtn agentGroupDetails={item} instanceId={instanceId} />
        ),
        width: {
          minWidth: '130px',
          width: '30%',
        },
      },
    ],
  }
}

const countryListTableConfig = (
  type: InstancePaymentOptionType,
  instanceId?: string
): TableConfig<Country> => {
  return {
    columns: [
      {
        id: 'name',
        header: 'Name',
        contentColumn: item => item.name,
        width: {
          minWidth: '190px',
          width: '70%',
        },
      },

      {
        id: 'control-btn',
        header: '',
        contentColumn: item => (
          <GettingDataByCountryBtn countryId={item.id} instanceId={instanceId} type={type} />
        ),
        width: {
          minWidth: '130px',
          width: '30%',
        },
      },
    ],
  }
}

const allAgentGroupsTableConfig = (instanceId?: string): TableConfig<AgentGroup> => {
  return {
    columns: [
      {
        id: 'name',
        header: 'Name',
        contentColumn: item => item.title,
        width: {
          minWidth: '190px',
          width: '70%',
        },
      },

      {
        id: 'control-btn',
        header: '',
        contentColumn: item => (
          <AgentGroupAssignBtn agentGroupDetails={item} instanceId={instanceId} />
        ),
        width: {
          minWidth: '130px',
          width: '30%',
        },
      },
    ],
  }
}

//remittance-payment-provider
const allPaymentProviderFeesTableConfig = (
  instanceId?: string
): TableConfig<PaymentProviderFee> => {
  return {
    columns: [
      {
        id: 'name',
        header: 'Provider Name',
        contentColumn: item => item.paymentProvider.name,
        width: {
          minWidth: '200px',
          width: '25%',
        },
      },
      {
        id: 'fixed-fee',
        header: 'Fixed Fee',
        contentColumn: item => item.feeValue.fixedValue?.toFixed(2) || '-',
        // width: '20%',
        width: {
          minWidth: '140px',
          width: '20%',
        },
      },
      {
        id: 'percent-fee',
        header: 'Percent Fee',
        contentColumn: item => `${item.feeValue.percentValue?.toFixed(2)}%` || '',
        // width: '20%',
        width: {
          minWidth: '140px',
          width: '20%',
        },
      },

      {
        id: 'status',
        header: 'Status',
        contentColumn: item => item.status,
        // width: '20%',
        width: {
          minWidth: '100px',
          width: '15%',
        },
      },

      {
        id: 'default-values',
        header: 'Default Values',
        contentColumn: item =>
          `${item.defaultValue.fixedValue} + ${item.defaultValue.percentValue}%`,
        width: {
          minWidth: '150px',
          width: '20%',
        },
      },
    ],
  }
}

const paymentOptionsByCountryTableConfig = (
  instanceId?: string
): TableConfig<PaymentOptionByCountry> => {
  return {
    columns: [
      {
        id: 'payment-provider',
        header: 'Payment Provider',
        contentColumn: item => item.paymentProviderMethod.paymentProvider.name,
        // width: '30%',
        width: {
          minWidth: '150px',
          width: '15%',
        },
      },

      {
        id: 'payment-method',
        header: 'Payment Method',
        contentColumn: item => PaymentMethod[item.paymentProviderMethod.paymentMethod],
        // width: '30%',
        width: {
          minWidth: '150px',
          width: '10%',
        },
      },

      {
        id: 'currency',
        header: 'Currency',
        contentColumn: item => item.currency,
        // width: '30%',
        width: {
          minWidth: '100px',
          width: '10%',
        },
      },

      {
        id: 'fixed-fee',
        header: 'Fixed Fee',
        contentColumn: item => item.fee.fixedValue,
        // width: '20%',
        width: {
          minWidth: '100px',
          width: '20%',
        },
      },
      {
        id: 'percent-fee',
        header: 'Percent Fee',
        contentColumn: item => item.fee.percentValue,
        // width: '20%',
        width: {
          minWidth: '100px',
          width: '20%',
        },
      },

      {
        id: 'control-btn',
        header: '',
        contentColumn: item => (
          <ControlButtonCell paymentOptionData={item} instanceId={instanceId} />
        ),
        width: {
          minWidth: '180px',
          width: '15%',
        },
      },
    ],
  }
}

const instanceCompaniesTableConfig = (instanceId?: string): TableConfig<InstanceCompanyDetails> => {
  return {
    columns: [
      {
        id: 'companyName',
        header: 'Name',
        contentColumn: item => item.companyName,
        width: {
          minWidth: '200px',
          width: '20%',
        },
      },

      {
        id: 'bankName',
        header: 'Bank Name',
        contentColumn: () => 'CBZ',
        width: {
          minWidth: '200px',
          width: '20%',
        },
      },
      {
        id: 'accountNumber',
        header: 'Account Number',
        contentColumn: item => item.accountNumber,
        width: {
          minWidth: '170px',
          width: '18%',
        },
      },
      {
        id: 'amlInspectionStatus',
        header: 'Aml Inspection Status',
        contentColumn: item => (
          <>
            {item.amlInspectionStatus === AmlInspectionStatus.Verified && (
              <CheckCircleIcon fontSize="small" sx={{ color: '#28E5AA' }} />
            )}
            {AmlInspectionStatus[item.amlInspectionStatus]}
          </>
        ),
        width: {
          minWidth: '145px',
          width: '18%',
        },
      },
      {
        id: 'activated',
        header: 'Activated',
        contentColumn: item => (item.isActive ? 'Active' : 'Inactive'),
        width: {
          minWidth: '130px',
          width: '10%',
        },
      },
      {
        id: 'control-btn',
        header: '',
        contentColumn: item => (
          <CompanyControlButtonCell companyId={item.id} instanceId={instanceId} />
        ),
        width: {
          minWidth: '225px',
          width: '20%',
        },
      },
    ],
  }
}

const remittanceTableConfig = {
  instanceCurrencyTableConfig,
  agentGroupsForInstanceTableConfig,
  allAgentGroupsTableConfig,
  currencyTableConfig,

  //remittance-payment-provider
  allPaymentProviderFeesTableConfig,
  countryListTableConfig,
  paymentOptionsByCountryTableConfig,

  instanceCompaniesTableConfig,
}

export default remittanceTableConfig
